<template>
  <div>
    <div class="inputContainer">
      <label class="labelStyle" :class="{ inFocusInput: isFocused }" :for="id">
        {{ label }}
      </label>

      <Field
        :rules="rules"
        :placeholder="placeholder"
        :id="id"
        class="inputStyle font-size-md"
        @focus="onFocus"
        :type="typeInput"
        v-model="inputValue"
        @input="updateValue"
        @blur="onBlur"
        :name="name"
      />
    </div>
    <validation-error :name="name" class="errorMessage" />
    <ErrorMessage :name="name" class="errorMessage" />
  </div>
</template>

<script>
import ValidationError from "@/components/ValidationError.vue";
import CustomInput from "@/mixins/CustomInput";
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "InputComponent",
  components: {
    ValidationError,
    Field,
    ErrorMessage,
  },
  mixins: [CustomInput],
  props: {
    label: {
      type: String,
      default: "",
    },
    typeInput: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
    },
    name: {
      type: String,
      default: "",
    },
    rules: {},
    placeholder: {
      type: String,
      default: "",
    },
  },
  data(){
    return {
      inputValue: null
    }
  },
  watch: {
    modelValue: function () {
      this.inputValue = this.modelValue;
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputContainer {
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  border-bottom: 1px solid map-get($colors, "gray");
  .inputStyle {
    color: map-get($colors, "black");
    border: none;
    width: 100%;
    height: 30px;
    line-height: 16px;
    background-color: transparent;
    &:focus {
      border: none;
      outline: none;
    }
    &:active {
      background-color: transparent;
    }
  }

  .labelStyle {
    position: absolute;
    bottom: 5px;
    font-size: 16px;
    transition: ease-in-out 0.2s;
    color: map-get($colors, "secondary");
    font-weight: 400;
  }

  .inFocusInput {
    bottom: 26px;
    font-size: 14px;
    transition: ease-in-out 0.2s;
  }
}
.errorMessage {
  color: red;
  left: 0;
}
</style>
