<template>
  <div
    class="tabButton font-size-big"
    @click="onTabClick()"
    v-bind:class="{ activeTab: active }"
  >
    {{ label }}
    <div class="arrow-right"></div>
  </div>
</template>

<script>
export default {
  name: "TabsComponent",
  props: {
    label: {
      type: String
    },
    active: {
      type: Boolean
    }
  },
  methods: {
    onTabClick() {
      this.$emit("onTabClick");
    }
  }
};
</script>

<style lang="scss" scoped>
.tabButton {
  padding: 18px 0 18px 20px;
  display: flex;
  flex: 1;
  border-bottom: 1px solid map-get($colors, "gray");
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
  color: map-get($colors, "black");
  justify-content: space-between;
  .arrow-right {
    background-image: url(~@/assets/arrow_down.svg);
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(-90deg);
  }
}
.activeTab {
  margin-right: 0;
  background-color: map-get($colors, "gray");
  padding-right: 20px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

@include media-breakpoint-down(md) {
  .tabButton {
    padding: 10px 0;
    margin-right: 0;
    color: map-get($colors, "secondary");
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
    .arrow-right {
      display: none;
    }
  }
  .activeTab {
    padding-right: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-color: transparent;
    border-bottom: 2px solid map-get($colors, "twiks");
  }
}
</style>
