<template>
  <div class="buttonContainer">
    <div class="buttonBox" :class="{ loadingStyle: loading }">
      <button
        class="btn btn-primary buttonStyle"
        @click="save"
        :type="buttonType"
      >
        {{ $t("global.save") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupButton",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String,
      default: ""
    }
  },
  methods: {
    save() {
      this.$emit("save");
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonContainer {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  width: 100%;
  align-self: center;
  margin-bottom: 50px;
  .buttonBox {
    width: 100%;
    position: relative;
  }
  .loadingStyle {
    opacity: 0.5;
  }
}
.buttonStyle {
  height: 48px;
  width: 100%;
}
@include media-breakpoint-down(md) {
  .buttonContainer {
    max-width: 100%;
    margin-top: 50px;
  }
}
</style>
