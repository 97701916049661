<template>
  <Form v-on:submit="saveDetails" class="containerDetails">
    <div>
      <div class="inputBox pb-3">
        <InputComponent
            :placeholder="name"
            name="name"
            v-model="name"
            :label="$t('personal_info.name')"
            type-input="name"
        />
      </div>
      <div class="inputBox pb-3">
        <InputComponent
            :placeholder="email"
            name="email"
            v-model="email"
            :label="$t('personal_info.email')"
            type-input="email"
        />
      </div>
      <div class="inputBox pb-3">
        <NumberPrefixes
            name="number"
            v-model="phoneNumber"
            :number="phoneNumber"
        />
      </div>

      <!-- Awaiting confirmation -->
      <alert v-for="unconfirmedToken in user.unconfirmedTokens" :key="unconfirmedToken.id">
        <div class="color-black font-weight-bold">
          {{ $t("personal_info.unconfirmedTokens." + unconfirmedToken.type) }}
        </div>
        {{
          $t("personal_info.unconfirmedTokens.expires_in", {
            'value' : unconfirmedToken.value,
            'minutes': expiresIn(unconfirmedToken.expiration_time)
          })
        }}

        <div class="pt-2" v-if="unconfirmedToken.type === 'verify_phone'">
          <router-link :to="'/verifyPhone/verifyNewNumber/' + unconfirmedToken.value">
            Confirm now
          </router-link>
        </div>
      </alert>

    </div>
    <GroupButton buttonType="submit" :loading="loading"/>
  </Form>
</template>

<script>
import InputComponent from "@/components/InputComponent";
import GroupButton from "@/views/PersonalInformation/partials/GroupButton";
import {Form} from "vee-validate";
import {mapActions, mapGetters} from "vuex";
import NumberPrefixes from "@/components/NumberPrefixies";
import Alert from "@/components/Alert";

export default {
  name: "AccountDetails",
  components: {Alert, NumberPrefixes, GroupButton, InputComponent, Form},
  data() {
    return {
      activeTab: "details",
      email: "",
      name: "",
      phoneNumber: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["editProfile", "getProfile"]),
    expiresIn(expirationTime) {
      var now = this.moment(new Date());
      var end = this.moment(expirationTime);
      var duration = this.moment.duration(end.diff(now));
      var minutesLeft = Math.round(duration.asMinutes());

      return minutesLeft;
    },
    saveDetails: async function () {
      try {
        this.loading = true;
        await this.editProfile({
          name: this.name,
          email: this.email,
          phone: this.phoneNumber,
        });

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getProfile();
  },
  mounted() {
    this.email = this.user.email;
    this.name = this.user.name;
    this.phoneNumber = this.user.phone;
  },
  watch: {
    user() {
      this.email = this.user.email;
      this.name = this.user.name;
      this.phoneNumber = this.user.phone;
    },
  },
};
</script>

<style lang="scss" scoped>
.containerDetails {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .inputBox {
    max-width: 310px;
    width: 100%;
    margin-bottom: 25px;
  }
}

@include media-breakpoint-down(md) {
  .containerDetails {
    .inputBox {
      max-width: 100%;
    }
  }
}
</style>
