<template>
  <Form v-on:submit="saveDetails" class="containerChangesPassword">
    <div>
      <div class="inputBox">
        <InputComponent
          rules="required"
          name="old_password"
          v-model="oldPassword"
          :label="$t('personal_info.current_pass')"
          type-input="password"
        />
      </div>
      <div class="inputBox">
        <InputComponent
          rules="required"
          name="new_password"
          v-model="newPassword"
          :label="$t('personal_info.new_pass')"
          type-input="password"
        />
      </div>
      <div class="inputBox">
        <InputComponent
          rules="required"
          name="new_password_confirmation"
          v-model="newPasswordConfirmation"
          :label="$t('personal_info.repeat_pass')"
          type-input="password"
        />
      </div>
    </div>
    <GroupButton buttonType="submit" />
  </Form>
</template>

<script>
import GroupButton from "@/views/PersonalInformation/partials/GroupButton";
import InputComponent from "@/components/InputComponent";
import { Form } from "vee-validate";
import { mapActions } from "vuex";

export default {
  name: "ChangePassword",

  components: { GroupButton, InputComponent, Form },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: ""
    };
  },
  methods: {
    ...mapActions(["changePassword"]),

    saveDetails: async function() {
      try {
        await this.changePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          newPasswordConfirmation: this.newPasswordConfirmation
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.oldPassword = "";
        this.newPassword = "";
        this.newPasswordConfirmation = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.containerChangesPassword {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .inputBox {
    max-width: 310px;
    width: 100%;
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(md) {
  .containerChangesPassword {
    .inputBox {
      max-width: 100%;
    }
  }
}
</style>
