<template>
  <div class="container" id="PersonalInformation">
    <div class="profileContainer">
      <div class="tabsContainer">
        <TabsComponent
          :label="$t('personal_info.account_det')"
          @on-tab-click="onTabClick('details')"
          :active="activeTab === 'details'"
        />
        <TabsComponent
          :label="$t('personal_info.change_pass')"
          @on-tab-click="onTabClick('password')"
          :active="activeTab === 'password'"
        />
      </div>

      <div class="containerContext">
        <div class="titleContent font-size-xl">
          {{
            activeTab === "details"
              ? $t("personal_info.manage_account")
              : $t("personal_info.change_password")
          }}
        </div>
        <div
          class="tabsContent"
          v-bind:class="{ active: activeTab === 'details' }"
        >
          <AccountDetails />
        </div>

        <div
          class="tabsContent"
          v-bind:class="{ active: activeTab === 'password' }"
        >
          <ChangePassword />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabsComponent from "@/views/PersonalInformation/partials/TabsComponent";
import AccountDetails from "@/views/PersonalInformation/partials/AccountDetails";
import ChangePassword from "@/views/PersonalInformation/partials/ChangePassword";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "PersonalInformation",
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("users.personal_info"),
    })
  },
  data() {
    return {
      activeTab: "details",
      email: "",
      repeatEmail: "",
      number: ""
    };
  },
  components: { ChangePassword, AccountDetails, TabsComponent },
  methods: {
    onTabClick: function(tab) {
      this.activeTab = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  display: flex;
  .profileContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;

    .tabsContainer {
      padding-top: 40px;
      width: 300px;
      border-right: 1px solid map-get($colors, "gray");
      flex-grow: 0;
      flex-shrink: 0;
    }
    .containerContext {
      display: flex;
      padding: 40px 0 0 40px;
      width: 100%;
      flex-direction: column;
      min-height: 100%;
      .titleContent {
        line-height: 40px;
        font-weight: bold;
      }
      .tabsContent {
        width: 100%;
        height: 100%;
        display: none;
        flex-direction: column;
        padding-top: 25px;
      }
      .active {
        display: flex;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #PersonalInformation {
    height: auto;
    & > .header {
      padding: map-get($spacings, "medium") map-get($spacings, "medium");
      padding-right: 40%;
    }
    .profileContainer {
      flex-direction: column;
      width: 100%;

      .tabsContainer {
        display: flex;
        padding-top: 0;
        width: 100%;
        border-right: none;
        flex-direction: row;
        background-color: transparent;
      }
      .containerContext {
        padding: 30px 0 0 0;
        .titleContent {
          font-size: 20px;
        }
        .tabsContent {
          height: auto;
        }
      }
    }
  }
}
</style>
